const prod = {
  url: {
    //API: "https://weiniggateway.com", Need to add url for api on AWS
    API: "https://WeinigGatewayAPI.us-east-2.elasticbeanstalk.com"
  },
};

const dev = {
  url: {
    API: "https://localhost:5001",
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
