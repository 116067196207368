import { KeyboardArrowDown, KeyboardArrowUp, Search } from "@mui/icons-material";
import { Button, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, CircularProgress, Modal, Collapse } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { AddStoreNotification } from "../../Functions/StoreNotification";
import { config } from "../../Functions/Constants";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import SubmitPasswordResetRequest from "../../Functions/PasswordReset";
import Footer from "../Footer";

const AdminAccountSearch = () => {
  const [accountName, setAccountName] = useState("");
  const [category, setcategory] = useState("Company");
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("weinigPortalUser")));
  const [selectedProductName, setSelectedProductName] = useState("");
  const [numberOfLicenses, setNumberOfLicenses] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [passwordTextFieldColor, setPasswordTextFieldColor] = useState("primary");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createAccountModalOpen, setCreateAccountModalOpen] = useState(false);
  const [passwordWarning, setPasswordWarning] = useState("");
  const [deleteLicenseModal, setDeleteLicenseModal] = useState(false);
  const [licenseToDelete, setLicenseToDelete] = useState({});
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState({});
  const [passwordBorderBottom, setPasswordBorderBottom] = useState("1px solid white");
  const [deleteUserDeviceLicenseModal, setDeleteUserDeviceLicenseModal] = useState(false);
  const [userDeviceLicenseToDelete, setUserDeviceLicenseToDelete] = useState({});
  const [rowToUpdate, setRowToUpdate] = useState({});
  const [newUser, setNewUser] = useState({
    username: "",
    emailAddress: "",
    password: "",
    confirmPassword: ""
  });
  const [newAccount, setNewAccount] = useState({
    SalesforceId: "",
    CompanyName: "",
    Username: "",
    StreetAddress: "",
    City: "",
    ZipCode: "",
    State: "",
    PhoneNumber: "",
    PrimaryContact: "",
    Email: "",
    Type:"Admin"
  }); 

  async function handleSearch(e){//check here to see if the selected product and amount get reset on a search and even on adding a new product.
      e.preventDefault();
      setIsLoading(true)
      try {
          const getAccounts = () => axios({
              method: "POST",
              url: config.url.API + "/Accounts/search-accounts",
              data: JSON.stringify({
                searchCriteria: accountName,
                searchtype: category,
              }),
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://weinigportal.com/",
                Authorization: "Bearer " + user.token,
              },
            });
          setCompanies([])
          setSelectedCompany({})
          var response = await getAccounts();

          if (response.data.statusCode === 401) {
            setIsLoading(false)
              setCompanies([])
              setSelectedProductName("")
              setNumberOfLicenses("")
              AddStoreNotification(
                "How did you get there?",
                "danger",
                "You're not authorized to be on that page."
              );
              navigate("/");
            } else if (response.data.statusCode === 204) {
              setIsLoading(false)
              setCompanies([])
              setSelectedProductName("")
              setNumberOfLicenses("")
              AddStoreNotification(
                "It's dangerous to go alone",
                "info",
                "There was no account matching that criteria"
              );
            } else if (response.data.statusCode === 422) {
              setIsLoading(false)
              setCompanies([])
              setSelectedProductName("")
              setNumberOfLicenses("")
              AddStoreNotification(
                "Error",
                "danger",
                "The server experienced an unknown error"
              );
            } else if (!!response.data) {
              setIsLoading(false)
              setCompanies(response.data)
              setSelectedProductName("")
              setNumberOfLicenses("")
            }
        } catch (error) {
          setIsLoading(false)
          setCompanies([])
          AddStoreNotification("Error","danger", error.message);
        }
  }

  async function handleAccountSearch(){
    try {
      const getAccounts = () => axios({
          method: "POST",
          url: config.url.API + "/Accounts/search-accounts",
          data: JSON.stringify({
            searchCriteria: selectedCompany.sfid,
            searchtype: "sfId",
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://weinigportal.com/",
            Authorization: "Bearer " + user.token,
          },
        });
      var response = await getAccounts();
      if (!!response.data) {
        setSelectedCompany(response.data[0])
        setSelectedProductName("")
        setNumberOfLicenses("")
      }
    } catch (error) {
      console.log(error.message)
      setSelectedProductName("")
      setNumberOfLicenses("")
    }
  }

  async function handleAccountUpdate(){
      var tempAccount = selectedCompany;
      try {
          setIsLoading(true);
          const updateCompany = () => axios({
              method: "POST",
              url: config.url.API + "/Accounts/update-account",
              data: JSON.stringify(tempAccount),
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://weinigportal.com/",
                Authorization: "Bearer " + user.token,
              },
            });
          var response = await updateCompany();

          if (response.data.statusCode === 401) {
            setIsLoading(false);
              AddStoreNotification(
                "How did you get there?",
                "danger",
                "You're not authorized to be on that page."
              );
              navigate("/");
            } else if (response.data.statusCode === 204) {
              setIsLoading(false);
              AddStoreNotification(
                "It's dangerous to go alone",
                "info",
                ""
              );
            } else if (response.data.statusCode === 422) {
              setIsLoading(false);
              AddStoreNotification(
                "Error",
                "danger",
                "The server experienced an unknown error"
              );
            } else if (response.data.statusCode === 200) {
              setIsLoading(false);
              handleAccountSearch();
              AddStoreNotification(
                "Success",
                "success",
                "Account information has been successfuly updated"
              );
            }
        } catch (error) {
          setIsLoading(false);
          AddStoreNotification("Error","danger", error.message);
        }
  }

  async function CreateUser() {
    setCreateModalOpen(false);
    let type = ""
    let message = ""
    if(newUser.password !== ""){
      if(newUser.password.length > 7){
        if(newUser.password === newUser.confirmPassword){
          setIsLoading(true);
          newUser.accountId = selectedCompany.sfid;
          newUser.company = selectedCompany.companyName;
          newUser.type = "User";
          newUser.streetAddress = selectedCompany.streetAddress;
          newUser.city = selectedCompany.city;
          newUser.state = selectedCompany.state;
          newUser.zipCode = selectedCompany.zipCode;
          newUser.action = user.action;
          newUser.fullyQualifiedAddress = selectedCompany.fullyQualifiedAddress;
          newUser.phoneNumber = selectedCompany.phoneNumber;
          newUser.primaryContact = selectedCompany.primaryContact;
          newUser.status = user.status;
          newUser.verified = user.verified;
          newUser.token = user.token;
          newUser.accessLevel = "1" //The default access level for a new user is 1.

          var response = await axios({
            method: "post",
            url: config.url.API + "/Users/create-user",
            data: JSON.stringify(newUser),
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + newUser.token,
              "Access-Control-Allow-Origin": "https://weinigportal.com/",
            },
          });

          type = "danger";

          if (
            response.data.statusCode.toString() !== "409" &&
            response.data.statusCode.toString() !== "401"
          ) 
          {
            setNewUser({
              ...newUser,
              accountId : selectedCompany.sfid,
              company : selectedCompany.companyName,
              type : "User",
              streetAddress : selectedCompany.streetAddress,
              city : selectedCompany.city,
              state : selectedCompany.state,
              zipCode : selectedCompany.zipCode,
              action : user.action,
              fullyQualifiedAddress : selectedCompany.fullyQualifiedAddress,
              phoneNumber : selectedCompany.phoneNumber,
              primaryContact : selectedCompany.primaryContact,
              status : user.status,
              verified : user.verified,
              token : user.token
            });
            var newArr = [...selectedCompany.users]
            newArr.push(newUser)
            setSelectedCompany({
              ...selectedCompany,
              users: newArr
            })
            type = "success";
          }
          setIsLoading(false);
          message = "Could not add user!";
          if (type === "success") message = "User was Created!";
          AddStoreNotification("Creating User", type, message);
          
        }else{
          type = "warning"
          message = "passwords do not match"
          AddStoreNotification("Error Creating User", type, message);
        }
    }else{
      type = "warning"
      message = "password must be at least 8 characters long"
      AddStoreNotification("Error Creating User", type, message);
    }
    }else{
      type = "warning"
        message = "password cannot be empty"
        AddStoreNotification("Error Creating User", type, message);
    }
    setNewUser({username: "", emailAddress: "", password: "", confirmPassword: ""});
  }

  async function CreateAccount() {
    if(Object.values(newAccount).every(property => property !== "")){
      setIsLoading(true);
      setCreateAccountModalOpen(false);
      var response = await axios({
        method: "post",
        url: config.url.API + "/Users/create-admin-user",
        data: JSON.stringify(newAccount),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
          "Access-Control-Allow-Origin": "https://weinigportal.com/",
        },
      });

      var type = "danger";
      var message = "Could not create account!";

      if (
        response.data.statusCode !== "409" ||
        response.data.statusCode !== "401"
      ) {
        setIsLoading(false);
        setNewAccount({SalesforceId: "",CompanyName: "",StreetAddress: "",
        City: "",ZipCode: "",State: "",PhoneNumber: "",PrimaryContact: "",Email: "", Type: "Admin"})
        type = "success";
      }
      if (type === "success"){
        setIsLoading(false);
        message = "Account was Created!";
        AddStoreNotification("Created Account", type, message);
      }else{
        setIsLoading(false);
        setNewAccount({SalesforceId: "",CompanyName: "",StreetAddress: "",
        City: "",ZipCode: "",State: "",PhoneNumber: "",PrimaryContact: "",Email: "", Type: "Admin"})
        type = "warning"
        message = "Something went wrong when trying to create an account."
        AddStoreNotification("Error Creating Account", type, message);
      }
    }else{
      setIsLoading(false);
      AddStoreNotification("All fields must be filled", "info", "Please fill in every field before trying to create an account.");
    }
  }

  async function DeleteUser() {
    var DeletedUser = userToDelete;
    try {
      setIsLoading(true);
      var response = await axios({
        method: "delete",
        url: config.url.API + "/Users/delete-user",
        data: JSON.stringify(DeletedUser),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
          "Access-Control-Allow-Origin": "https://weinigportal.com/",
          "Access-Control-Allow-Methods": "*",
        },
      });

      let type = "danger";

      if (response.status === 200) {
        setIsLoading(false);
        setDeleteUserModal(false);
        type = "success";
        const index = selectedCompany.users.indexOf(DeletedUser);
        var newArr = [...selectedCompany.users];
        if (index > -1) {
          newArr.splice(index, 1)
          setSelectedCompany({
            ...selectedCompany,
            users: newArr
          })
        }
      } else if (response.status === 404) {
        setIsLoading(false);
        setDeleteUserModal(false);
        type = "danger";
      }
      let message = "Could not delete user!";
      if (type === "success") message = "User has been removed!";
      AddStoreNotification("Delete", type, message);
    } catch (err) {
      setIsLoading(false);
      setDeleteUserModal(false);
      AddStoreNotification("Error", "danger", err.message);
    }
  }

  async function handleUserUpdate(e, userToUpdate){
    e.preventDefault();
    try {
        setIsLoading(true);
        const updateCompany = () => axios({
            method: "PUT",
            url: config.url.API + "/Users/update-user",
            data: JSON.stringify(userToUpdate),
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "https://weinigportal.com/",
              Authorization: "Bearer " + user.token,
            },
          });
        var response = await updateCompany();

        if (response.data.statusCode === 401) {
            setIsLoading(false);
            AddStoreNotification(
              "How did you get there?",
              "danger",
              "You're not authorized to be on that page."
            );
            navigate("/");
          } else if (response.data.statusCode === 204) {
            setIsLoading(false);
            AddStoreNotification(
              "It's dangerous to go alone",
              "info",
              ""
            );
          } else if (response.data.statusCode === 422) {
            setIsLoading(false);
            AddStoreNotification(
              "Error",
              "danger",
              "The server experienced an unknown error"
            );
          } else if (response.data.statusCode === 200) {
            setIsLoading(false);
            handleAccountSearch();
            AddStoreNotification(
              "Success",
              "success",
              "User information has been successfuly updated"
            );
          }
      } catch (error) {
        setIsLoading(false);
        AddStoreNotification("Error","danger", error.message);
      }
  }

  async function addWithSubmit(e){
    if(e.key === "Enter"){
      addProduct(e)
    }
  }

  async function addProduct(e){
    e.preventDefault();
    var tempProduct = {}
    tempProduct.name = selectedProductName;
    tempProduct.numberOfLicenses = numberOfLicenses;
    tempProduct.userSfId = selectedCompany.sfid;
    tempProduct.numberUsed = 0;
    tempProduct.licenses = [];
    var response = await axios({
      method: "POST",
      url: config.url.API + "/Products/add-product",
      data: JSON.stringify({
        "Name":selectedProductName,
        "NumberOfLicenses":Number(numberOfLicenses),
        "UserSfId":selectedCompany.sfid
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://weinigportal.com/",
        Authorization: "Bearer " + user.token,
      },
    });

    if (response.data.statusCode === 201 || response.data.statusCode === 202) {
      AddStoreNotification("Success!", "success", "Product Added!");
      var newArr = [...selectedCompany.products];
      newArr.push(tempProduct);
      setSelectedCompany({
        ...selectedCompany,
        products: newArr});
      setSelectedProductName("")
      setNumberOfLicenses("")
    } else {
      AddStoreNotification(
        "Error!",
        "danger",
        "The server was unable to process your request at this time."
      );
      setSelectedProductName("")
      setNumberOfLicenses("")
    }

  }

  async function deleteProduct() {
    var p = licenseToDelete;
    var tempProduct = {}
    tempProduct.name = p.name;
    tempProduct.numberOfLicenses = p.numberOfLicenses;
    tempProduct.userSfId = selectedCompany.sfid;

    var result = await axios({
        method: "DELETE",
        url: config.url.API + "/Products/delete-product",
        data: JSON.stringify({
          "Name":tempProduct.name,
          "NumberOfLicenses":Number(tempProduct.numberOfLicenses),
          "UserSfId":tempProduct.userSfId
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://weinigportal.com/",
          Authorization: "Bearer " + user.token,
        },
      });

    if (result.data === "Deleted") {
      const index = selectedCompany.products.indexOf(p);
      if (index > -1) {
        var newArr = [...selectedCompany.products]
        newArr.splice(index, 1)
        setSelectedCompany({
          ...selectedCompany,
          products: newArr});
      }
      setDeleteLicenseModal(false);
      AddStoreNotification("Success!", "success", "Product has been removed!");
    } else {
      setDeleteLicenseModal(false);
      AddStoreNotification(
        "Error",
        "danger",
        "The server was unable to process the request at this time."
      );
    }
  }

  function handleAccountChange(e) {

    switch (e.target.name) {
      case "company":
        setSelectedCompany({
          ...selectedCompany,
          companyName: e.target.value});
        break;
      case "phone":
        setSelectedCompany({
          ...selectedCompany,
          phoneNumber: e.target.value});
        break;
      case "email":
        setSelectedCompany({
          ...selectedCompany,
          emailAddress: e.target.value});
        break;
      case "contact":
        setSelectedCompany({
          ...selectedCompany,
          primaryContact: e.target.value});
        break;
      case "id":
        setSelectedCompany({
          ...selectedCompany,
          sfid: e.target.value});
        break;
      case "address":
        setSelectedCompany({
          ...selectedCompany,
          streetAddress: e.target.value});
        break;
      case "city":
        setSelectedCompany({
          ...selectedCompany,
          city: e.target.value});
        break;
      case "state":
        setSelectedCompany({
          ...selectedCompany,
          state: e.target.value});
        break;
      case "zip":
        setSelectedCompany({
          ...selectedCompany,
          zipCode: e.target.value});
        break;
      default:
        break;
    }
  }

  function handleUserChange(e, userIndex) {
    let newArr = [...selectedCompany.users];

    switch (e.target.name) {
      case "username":
        newArr[userIndex].username = e.target.value;
        break;
      case "company":
        newArr[userIndex].company = e.target.value;
        break;
      case "phone":
        newArr[userIndex].phoneNumber = e.target.value;
        break;
      case "email":
        newArr[userIndex].emailAddress = e.target.value;
        break;
      case "contact":
        newArr[userIndex].primaryContact = e.target.value;
        break;
      case "id":
        newArr[userIndex].accountId = e.target.value;
        break;
      case "address":
        newArr[userIndex].streetAddress = e.target.value;
        break;
      case "city":
        newArr[userIndex].city = e.target.value;
        break;
      case "state":
        newArr[userIndex].state = e.target.value;
        break;
      case "zip":
        newArr[userIndex].zipCode = e.target.value;
        break;
      case "accountType":
        newArr[userIndex].type = e.target.value;
        break;
      case "accessLevel":
        newArr[userIndex].accessLevel = e.target.value;
        break;
      default:
        break;
    }
    setSelectedCompany({
      ...selectedCompany,
      users: newArr
    })
  }

  function handleNewUserUpdate(e, userToUpdate) {
    switch (e.target.name) {
    case "username":
        setNewUser({
            ...userToUpdate,
            username: e.target.value});
        break;
    case "email":
        setNewUser({
            ...userToUpdate,
            emailAddress: e.target.value});
        break;
    case "password":
        if(e.target.value.length < 8){
            setPasswordTextFieldColor("error");
            setPasswordBorderBottom("1px solid red");
            setPasswordWarning("* password must be at least 8 characters");
        }else{
          if(e.target.value !== newUser.confirmPassword){
            setPasswordTextFieldColor("error");
            setPasswordBorderBottom("1px solid red");
            setPasswordWarning("* passwords do not match");
          }else{
            setPasswordTextFieldColor("primary")
            setPasswordBorderBottom("1px solid white");
            setPasswordWarning("")
          }
        }
        setNewUser({
            ...userToUpdate,
            password: e.target.value});
        break;
    case "confirmPassword":
        if(e.target.value !== newUser.password){
          if(newUser.password < 8){
            setPasswordTextFieldColor("error");
            setPasswordBorderBottom("1px solid red");
            setPasswordWarning("* password must be at least 8 characters");
          }else{
            setPasswordTextFieldColor("error")
            setPasswordBorderBottom("1px solid red");
            setPasswordWarning("* passwords do not match")
          }
        }else{
          if(newUser.password < 8){
            setPasswordTextFieldColor("error");
            setPasswordBorderBottom("1px solid red");
            setPasswordWarning("* password must be at least 8 characters");
          }else{
              setPasswordTextFieldColor("primary")
              setPasswordBorderBottom("1px solid white");
              setPasswordWarning("")
          }
        }
        setNewUser({
            ...userToUpdate,
            confirmPassword: e.target.value});
        break;
    default:
        break;
    }
  }

  function handleNewAccountUpdate(e, accountToUpdate) {
  switch (e.target.name) {
  case "sfID":
    setNewAccount({
          ...accountToUpdate,
          SalesforceId: e.target.value});
      break;
  case "email":
      setNewAccount({
          ...accountToUpdate,
          Email: e.target.value});
      break;
  case "streetAddress":
      setNewAccount({
          ...accountToUpdate,
          StreetAddress: e.target.value});
      break;
  case "state":
    setNewAccount({
        ...accountToUpdate,
        State: e.target.value});
    break;
  case "phoneNumber":
    setNewAccount({
        ...accountToUpdate,
        PhoneNumber: e.target.value});
    break;
  case "username":
    setNewAccount({
        ...accountToUpdate,
        Username: e.target.value});
    break;
  case "companyName":
    setNewAccount({
        ...accountToUpdate,
        CompanyName: e.target.value});
    break;
  case "city":
    setNewAccount({
        ...accountToUpdate,
        City: e.target.value});
    break;
  case "areaCode":
    setNewAccount({
        ...accountToUpdate,
        ZipCode: e.target.value});
    break;
  case "contactName":
    setNewAccount({
        ...accountToUpdate,
        PrimaryContact: e.target.value});
    break;

  default:
      break;
  }
  }

  function sendToWebpage(name){
  switch(name.toLowerCase()){
    case "cut":
      window.open(
        'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
        '_blank'
      );
    break;
    case "millvision":
      window.open(
        'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
        '_blank'
      );
    break;
    case "envision":
      window.open(
        'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
        '_blank'
      );
    break;
    case "mvlabeler":
      window.open(
        'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
        '_blank'
      );
    break;
    case "cadet":
      window.open(
        'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
        '_blank'
      );
    break;
    case "jobview":
      window.open(
        'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
        '_blank'
      );
    break;
    case "accumulator":
      window.open(
        'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
        '_blank'
      );
    break;
    default:
      window.open(
        'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
        '_blank'
      );
    break;
  }
  }

  function Row(productRow){
    const {row} = productRow;
    const [open, setOpen] = useState(false);

    return(
      <React.Fragment>
        <TableRow key={row.name} >
          <TableCell>
            <IconButton size="small" onClick={() => { if(row.licenses.length !== 0){setOpen(!open)}}}>
              {open ? <KeyboardArrowUp sx={{color:"white"}}/> : <KeyboardArrowDown sx={{color:"white"}}/>}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" sx={{fontSize:14.5, color:"white"}}>{row.name}</TableCell>
          <TableCell align="right" sx={{fontSize:14.5, color:"#00963f"}}>{row.numberOfLicenses}</TableCell>
          <TableCell align="right" sx={{fontSize:14.5, color:"#00963f"}}>{row.numberUsed}</TableCell>
          <TableCell align="right" sx={{fontSize:14.5, color:"#00963f"}}>{row.licenses.length}</TableCell> 
          <TableCell align="right">
            {/* <Button variant="contained" size="small" sx={{fontSize:14, textTransform:"none", marginRight:"15px"}} onClick={(e) => {sendToWebpage(row.name)}}>manual</Button> */}
            <Button variant="contained" color="error" size="small" sx={{fontSize:14, textTransform:"none"}} onClick={(e) => {setLicenseToDelete(row); setDeleteLicenseModal(true)}}>delete</Button>
          </TableCell>
        </TableRow>
        {row.licenses.length !== 0 &&
          <TableRow>
            <TableCell sx={{paddingBottom:0, paddingTop:0}} colSpan={7}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{margin:1}}>
                  <Typography sx={{fontSize:16, color:"white"}}>Product Devices</Typography>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{fontSize:12, fontWeight:"bold", color:"white"}}>Product</TableCell>
                        <TableCell sx={{fontSize:12, fontWeight:"bold", color:"white"}} align="right">Device Name</TableCell>
                        <TableCell sx={{fontSize:12, fontWeight:"bold", color:"white"}} align="right">License Active</TableCell>
                        <TableCell sx={{fontSize:12, fontWeight:"bold", color:"white"}} align="right">Activate/Deactivate</TableCell>
                        <TableCell sx={{fontSize:12, fontWeight:"bold", color:"white"}} align="right">Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.licenses.map((license) => (
                        <TableRow key={license.hostName}>
                          <TableCell component="th" scope="row" sx={{fontSize:12, color:"white"}}>{license.product}</TableCell>
                          <TableCell align="right" sx={{fontSize:12, color:"white"}}>{license.hostName}</TableCell>
                          <TableCell align="right">
                            {license.active === "1" ? <Typography sx={{color:"#00963f", fontSize:12}}>yes</Typography> : <Typography sx={{color:"red", fontSize:12}}>no</Typography>}
                          </TableCell>
                          <TableCell align="right">
                            {license.active === "1" ? <Button onClick={() => {updateLicense(license, row)}} variant="contained" color="error" sx={{width:"75px", fontSize:12, textTransform:"none"}}>deactivate</Button> :
                              <Button onClick={() => {updateLicense(license, row)}} variant="contained" sx={{width:"75px", fontSize:12, textTransform:"none", background:"#00963f", '&:hover':{backgroundColor:"#007330"}}}>activate</Button>}
                          </TableCell>
                          <TableCell align="right">
                            <Button onClick={() => {setUserDeviceLicenseToDelete(license); setRowToUpdate(row); setDeleteUserDeviceLicenseModal(true);}} variant="contained" color="error" sx={{width:"75px", fontSize:12, textTransform:"none"}}>delete</Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        }
      </React.Fragment>
    )
  }

  async function updateLicense(license, row){
    if(!!license.product && license.active !== null && !!license.hostName && !!license.productCode){
      var active = "0";
      var apiEndPoint = "/License/remove-license";
      var header = "A license for " + license.product + " was successfully deactivated";
      var message = license.product + " for user " + license.hostName + " was successfullly deactivated!";
      var addToLicenseCount = -1;

      if(license.active === "0"){
        active = "1";
        apiEndPoint = "/License/activate-license";
        header = "A license for " + license.product + " was successfully activated";
        message = license.product + " for user " + license.hostName + " was successfullly activated!";
        addToLicenseCount = 1;
      }

      try {
        setIsLoading(true);
        var licenseToUpdated = {}
        licenseToUpdated.UserName = user.username;
        licenseToUpdated.Product = license.product;
        licenseToUpdated.ProductCode = license.productCode;
        licenseToUpdated.AccountId = selectedCompany.sfid;
        licenseToUpdated.Active = license.active.toString();
        licenseToUpdated.DateAdded = "";
        licenseToUpdated.HostName = license.hostName;
        licenseToUpdated.ClientId = "";
        licenseToUpdated.Code = license.productCode;
        licenseToUpdated.Software = "";
        const updateLicense = () => axios({
            method: "POST",
            url: config.url.API + apiEndPoint,
            data: JSON.stringify(licenseToUpdated),
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
              "Access-Control-Allow-Origin": "https://weinigportal.com/",
              "Access-Control-Allow-Methods": "*"
            },
          });
        var response = await updateLicense();
        if (response.status === 401) {
          setIsLoading(false)
            AddStoreNotification(
              "How did you get there?",
              "danger",
              "You're not authorized to be on that page."
            );
            navigate("/");
          } else if (response.status === 204) {
            setIsLoading(false)
            AddStoreNotification(
              "It's dangerous to go alone",
              "info",
              "There was no account matching that criteria"
            );
          } else if (response.status === 422) {
            setIsLoading(false)
            AddStoreNotification(
              "Error",
              "danger",
              "The server experienced an unknown error"
            );
          } else if (response.status === 202) {
            setIsLoading(false)
            license.active = active;
            row.numberUsed = row.numberUsed + addToLicenseCount;
            AddStoreNotification(
              header,
              "success",
              message
            );
          }
      } catch (error) {
        setIsLoading(false)
        AddStoreNotification("Error","danger", error.message);
      }   
    } 
  }

  async function deleteUserDeviceLicense(){
  var product = userDeviceLicenseToDelete.product;
  var hostName = userDeviceLicenseToDelete.hostName;
  try {
    setIsLoading(true);
    const deleteLicense = () => axios({
        method: "POST",
        url: config.url.API + "/License/delete-license",
        data: JSON.stringify(userDeviceLicenseToDelete),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
          "Access-Control-Allow-Origin": "https://weinigportal.com/",
          "Access-Control-Allow-Methods": "*"
        },
      });
    var response = await deleteLicense();
    if (response.status === 401) {
      setIsLoading(false)
        AddStoreNotification(
          "How did you get there?",
          "danger",
          "You're not authorized to be on that page."
        );
        navigate("/");
      } else if (response.status === 204) {
        setIsLoading(false)
        AddStoreNotification(
          "It's dangerous to go alone",
          "info",
          "There was no account matching that criteria"
        );
      } else if (response.status === 422) {
        setIsLoading(false)
        AddStoreNotification(
          "Error",
          "danger",
          "The server experienced an unknown error"
        );
      } else if (response.status === 202) {

        const indexOfLicense = rowToUpdate.licenses.indexOf(userDeviceLicenseToDelete);
        const indexOfRow = selectedCompany.products.indexOf(rowToUpdate);
        let numberActive = 0;
        if(indexOfLicense > -1 && indexOfRow > -1){
          rowToUpdate.licenses.splice(indexOfLicense, 1);
          rowToUpdate.licenses.forEach(license => {
            if(license.active === "1"){
              numberActive++;
            }
          });
          rowToUpdate.numberUsed = numberActive;
          var newProductsArr = selectedCompany.products;
          newProductsArr.splice(indexOfRow, 1, rowToUpdate);
          setSelectedCompany({
            ...selectedCompany,
            products: newProductsArr});
        }
        setIsLoading(false)
        AddStoreNotification(
          "Successfully delete license",
          "success",
          "The " + product + " license was deleted for the product device " + hostName
        );
      }
  } catch (error) {
    setIsLoading(false)
    AddStoreNotification("Error","danger", error.message);
  }
  }

  return(
      <Box width="100%" height="calc(100% - 52px)" backgroundColor="#161616" display="flex" alignItems="center" justifyContent="space-between" flexDirection="column" overflow="auto">
          <Box sx={{paddingBottom:"50px", width:"97%", minWidth:"727px"}} >
            <Paper sx={{marginTop:"20px", position:"relative", width: "100%", borderRadius:"0px", background:'#202020'}} elevation={1} square>
                <Box display="flex" alignItems={"center"} padding={"3px 0px 3px 0px"}>
                    <Typography flex={1} margin={"15px 10px 15px 15px"} minWidth={"120px"} fontSize={15} color={"white"}>Account Search</Typography>
                    <form onSubmit={(e) => handleSearch(e)} style={{display:'flex', alignItems:'center', flex:10}}>
                            <TextField label="account name" variant="outlined" 
                                value={accountName} onChange={(e) => {setAccountName(e.target.value)}} 
                                size="small" sx={{
                                  "& .MuiOutlinedInput-root": {"& > fieldset": { borderColor: "white" }},
                                  "& .MuiOutlinedInput-root.Mui-focused": {"& > fieldset": {borderColor: "white"}},
                                  "& .MuiOutlinedInput-root:hover": { "& > fieldset": { borderColor: "white"}},
                                  marginRight:"15px", width:"100%", height:"35px", minWidth: 150}}
                                InputProps={{style: {fontSize:14.5, color:"white"}}} InputLabelProps={{style: {fontSize:14.5, color:"white"}}} />
                            <FormControl sx={{minWidth: 150, fontSize:14.5}} size="small">
                                <InputLabel id="categoryLabel" sx={{fontSize:14.5, color:"white", "&.Mui-focused": {color: "white"}}}>category</InputLabel>
                                <Select
                                labelId="categoryLabel"
                                value={category}
                                label="category"
                                onChange={(e) => {setcategory(e.target.value)}}
                                sx={{color:"white",
                                  '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                  },
                                  '.Mui-focused': {
                                    color: "white",
                                  },
                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                  },
                                  '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                  },
                                  fontSize:14.5, height:"35px"}}
                                >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value={"Company"} sx={{fontSize:14.5}}>Company</MenuItem>
                                    <MenuItem value={"Contact"} sx={{fontSize:14.5}}>Contact</MenuItem>
                                    <MenuItem value={"PhoneNumber"} sx={{fontSize:14.5}}>Phone Number</MenuItem>
                                    <MenuItem value={"Email"} sx={{fontSize:14.5}}>Email</MenuItem>
                                </Select>
                            </FormControl>
                            <IconButton size="large" type="submit">
                                <Search sx={{color:"white"}}/>
                            </IconButton>
                            <Button variant="contained" sx={{fontSize:14, fontWeight:"bold", textTransform:"none", height:"35px", whiteSpace:"nowrap",
                            paddingLeft:"30px", paddingRight:"30px",minWidth:"165px", marginRight:"15px", boxShadow:"none", '&:hover':{boxShadow:"none"}}}
                              onClick={(e) => {setCreateAccountModalOpen(true)}}>
                            Create New Account
                            </Button>
                    </form>
                </Box>
            </Paper>
            
            {isLoading && <Box sx={{width:"100%", display:"flex", justifyContent:"center"}}><CircularProgress sx={{margin:"20px 0px 0px 0px", color:"#00A551"}} size="30px"/></Box>}
            {companies.length !== 0 &&
              <Paper sx={{marginTop:"20px", position:"relative",  width: "100%", background:"#202020", borderRadius:"10px"}} elevation={1} square>
                  <Table>
                      <TableHead>
                          <TableRow sx={{background: "#1D1D1D"}}>
                              <TableCell sx={{fontSize:14.5, fontWeight:"bold", color:"white"}}>Account</TableCell>
                              <TableCell sx={{fontSize:14.5, fontWeight:"bold", color:"white"}}>Contact</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {companies.map((row) => (
                              <TableRow hover key={row.companyName} onDoubleClick={(e) => {setSelectedCompany({...row})}} sx={{'&:hover':{cursor:"pointer"}, borderLeft: "2px solid #606060", borderRight: "2px solid #606060", borderTop: "0.5px solid #606060", borderBottom: "0.5px solid #606060"}}>
                                  <TableCell component="th" scope="row" sx={{fontSize:14.5, color:"white"}}>
                                      {row.companyName}
                                  </TableCell>
                                  <TableCell component="th" scope="row" sx={{fontSize:14.5, color:"white"}}>
                                      {row.primaryContact}
                                  </TableCell>
                              </TableRow>
                          ))}
                      </TableBody>
                  </Table>
              </Paper>
            }
          
            {Object.keys(selectedCompany).length !== 0 &&
              <Box  width="100%">
                <Paper sx={{marginTop:"20px", overflow:"hidden", background:"#202020", borderRadius:"0px"}} elevation={0} square>
                  <Box style={{alignItems:'center', flex:10}}>
                    <Typography flex={1} margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={15} fontWeight={"bold"} color={"white"}>{selectedCompany.companyName} account details</Typography>
                    <Box display="flex" margin={"15px 15px 15px 15px"}>
                      <TextField name="id" size="small" variant="filled" label="Id" value={selectedCompany.sfid} onChange={(e) =>{handleAccountChange(e)}} 
                      sx={{width:"20%", marginRight:"15px", borderBottom: "1px solid white"}}
                      InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                      InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>

                      <TextField name="company" size="small" variant="filled" label="Company" value={selectedCompany.companyName} onChange={(e) =>{handleAccountChange(e)}} 
                      sx={{width:"20%", marginRight:"15px", borderBottom: "1px solid white"}}
                      InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                      InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>

                      <TextField name="email" size="small" variant="filled" label="Email" value={selectedCompany.emailAddress} onChange={(e) =>{handleAccountChange(e)}} 
                      sx={{width:"20%", marginRight:"15px", borderBottom: "1px solid white"}} 
                      InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                      InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>

                      <TextField name="phone" size="small" variant="filled" label="Phone" value={selectedCompany.phoneNumber} onChange={(e) =>{handleAccountChange(e)}} 
                      sx={{width:"20%", marginRight:"15px", borderBottom: "1px solid white"}}
                      InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                      InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>

                      <TextField name="contact" size="small" variant="filled" label="Contact"value={selectedCompany.primaryContact} onChange={(e) =>{handleAccountChange(e)}} 
                      sx={{width:"20%", borderBottom: "1px solid white"}} 
                      InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                      InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>
                    </Box>
                    <Box display="flex" margin={"15px 15px 15px 15px"}>
                      <TextField name="address" size="small" variant="filled" label="Address" value={selectedCompany.streetAddress} onChange={(e) =>{handleAccountChange(e)}} 
                      sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}}  
                      InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                      InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>

                      <TextField name="city" size="small" variant="filled" label="City" value={selectedCompany.city} onChange={(e) =>{handleAccountChange(e)}} 
                      sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}}  
                      InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                      InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>

                      <TextField name="state" size="small" variant="filled" label="State" value={selectedCompany.state} onChange={(e) =>{handleAccountChange(e)}} 
                      sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}}  
                      InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                      InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>

                      <TextField name="zip" size="small" variant="filled" label="Zip" value={selectedCompany.zipCode} onChange={(e) =>{handleAccountChange(e)}} 
                      sx={{width:"25%", borderBottom: "1px solid white"}} 
                      InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                      InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>
                    </Box>
                    <Box display="flex" margin={"15px 15px 15px 15px"} alignItems={"center"}>
                      <Box sx={{width:"100%"}}>
                        <Button variant="contained" sx={{fontSize:14, float:"right", textTransform:"none"}} onClick={handleAccountUpdate}>
                            Update
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Paper>

                {selectedCompany.users.length !== 0 &&
                  <Paper sx={{marginTop:"20px", overflow:"hidden",background:"#202020", borderRadius:"0px"}} elevation={0} square>
                    <Box display="flex" alignItems="center" margin={"15px 15px 15px 15px"}>
                        <Typography flex={1}  minWidth={"120px"} fontSize={15} fontWeight={"bold"} color={"white"}>{selectedCompany.companyName} Users</Typography>
                        <Button variant="contained" sx={{fontSize:14, float:"right", textTransform:"none"}} onClick={(e) => {setCreateModalOpen(true)}}>
                            Create New User
                        </Button>
                    </Box>
                    {selectedCompany.users.map((user, index) => (
                      <Box style={{alignItems:'center', flex:10}}>
                        <Typography flex={1} margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={15} color={"white"}>{user.username} user details</Typography>
                        <Box display="flex" margin={"15px 15px 15px 15px"}>
                          <TextField name="username" size="small" variant="filled" label="username" value={user.username} onChange={(e) =>{handleUserChange(e, index)}} sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"white"}}}></TextField>
                          <TextField name="company" size="small" variant="filled" label="company" value={user.company} onChange={(e) =>{handleUserChange(e, index)}} sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"white"}}}></TextField>
                          <TextField name="phone" size="small" variant="filled" label="Phone" value={user.phoneNumber} onChange={(e) =>{handleUserChange(e, index)}} sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"white"}}}></TextField>
                          <TextField name="email" size="small" variant="filled" label="Email" value={user.emailAddress} onChange={(e) =>{handleUserChange(e, index)}} sx={{width:"25%", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"white"}}}></TextField>
                        </Box>
                        <Box display="flex" margin={"15px 15px 15px 15px"}>
                          <TextField name="contact" size="small" variant="filled" label="Contact"value={user.primaryContact} onChange={(e) =>{handleUserChange(e, index)}} sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"white"}}}></TextField>
                          <TextField name="id" size="small" variant="filled" label="Id" value={user.accountId} onChange={(e) =>{handleUserChange(e, index)}} sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white", color:"white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"white"}}}></TextField>
                          <TextField name="address" size="small" variant="filled" label="Address" value={user.streetAddress} onChange={(e) =>{handleUserChange(e, index)}} sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"white"}}}></TextField>
                          <TextField name="city" size="small" variant="filled" label="City" value={user.city} onChange={(e) =>{handleUserChange(e, index)}} sx={{width:"25%", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"white"}}}></TextField>
                      </Box>
                        <Box display="flex" margin={"15px 15px 15px 15px"} alignItems={"center"}>
                          <TextField name="state" size="small" variant="filled" label="State" value={user.state} onChange={(e) =>{handleUserChange(e, index)}} sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"white"}}}></TextField>
                          <TextField name="zip" size="small" variant="filled" label="Zip" value={user.zipCode} onChange={(e) =>{handleUserChange(e, index)}} sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"white"}}}></TextField>
                          <TextField name="accountType" size="small" variant="filled" label="Account Type"  value={user.type} onChange={(e) =>{handleUserChange(e, index)}} sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white", color:"white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"white"}}}></TextField>
                          <TextField name="accessLevel" size="small" variant="filled" label="Access Level" value={user.accessLevel} onChange={(e) =>{handleUserChange(e, index)}} sx={{width:"25%", borderBottom: "1px solid white", color:"white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"white"}}}></TextField>
                        </Box>
                        <Box display="flex" margin={"15px 15px 15px 15px"} alignItems={"center"}>
                          <Box sx={{width:"100%"}}>
                            <Button variant="contained" color="error" sx={{fontSize:14, float:"right", textTransform:"none"}} onClick={(e) => {setUserToDelete(user); setDeleteUserModal(true);}}>
                                Delete
                            </Button>
                            <Button variant="contained" sx={{fontSize:14, float:"right", marginRight:"15px", textTransform:"none", background:"#00963f", '&:hover':{backgroundColor:"#007330"}}} onClick={(e) => {SubmitPasswordResetRequest(user.username)}}>
                                Reset Password
                            </Button>
                            <Button variant="contained" sx={{fontSize:14, float:"right", marginRight:"15px", textTransform:"none"}} onClick={(e) => {handleUserUpdate(e, user)}}>
                                Update
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Paper>
                }
                  <Box backgroundColor="#202020" marginTop={"20px"}>
                      <Paper elevation={0} square sx={{borderRadius:"0px", background:"#202020"}}>
                          <Box display="flex" justifyContent="space-between" padding={"3px 0px 3px 0px"}>
                              <Typography padding={"15px 0px 15px 15px"} fontSize={15} color={"white"} fontWeight={"bold"}>{selectedCompany.companyName} Software</Typography>
                              <Box display="flex" alignItems="center">
                                  <Typography padding={"15px 15px 15px 15px"} minWidth={"76px"} fontSize={14.5} color={"white"}>Add licenses</Typography>
                                  <FormControl sx={{minWidth: 120, marginRight:"15px", fontSize:14.5}} size="small">
                                      <InputLabel id="productNameLabel" sx={{fontSize:14.5, color:"white", "&.Mui-focused": {color: "white"}}}>product</InputLabel>
                                      <Select
                                      labelId="productNameLabel"
                                      value={selectedProductName}
                                      label="product"
                                      onChange={(e) => {setSelectedProductName(e.target.value)}}
                                      sx={{color: "white",
                                      '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'white',
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'white',
                                      },
                                      '.Mui-focused': {
                                        color: "white",
                                      },
                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'white',
                                      },
                                      '.MuiSvgIcon-root ': {
                                        fill: "white !important",
                                      },
                                        fontSize:14.5, height:"35px"}}
                                      >
                                          <MenuItem value=""></MenuItem>
                                          <MenuItem value={"cut"} sx={{fontSize:14.5}}>Cut</MenuItem>
                                          <MenuItem value={"millvision"} sx={{fontSize:14.5}}>MillVision</MenuItem>
                                          <MenuItem value={"accumulator"} sx={{fontSize:14.5}}>Accumulator</MenuItem>
                                          <MenuItem value={"jobview"} sx={{fontSize:14.5}}>JobView</MenuItem>
                                          <MenuItem value={"envision"} sx={{fontSize:14.5}}>Envision</MenuItem>
                                          <MenuItem value={"cadet"} sx={{fontSize:14.5}}>Cadet</MenuItem>
                                          <MenuItem value={"machine monitoring"} sx={{fontSize:14.5}}>Machine Monitoring</MenuItem>
                                          <MenuItem value={"pack"} sx={{fontSize:14.5}}>Pack</MenuItem>
                                          <MenuItem value={"mvlabeler"} sx={{fontSize:14.5}}>MVLabeler</MenuItem>
                                          <MenuItem value={"custom reports"} sx={{fontSize:14.5}}>Custom Reports</MenuItem>
                                      </Select>
                                  </FormControl>
                                  <TextField label="# of licenses" variant="outlined" 
                                  value={numberOfLicenses} onChange={(e) => {setNumberOfLicenses(e.target.value)}} 
                                  size="small" sx={{
                                    "& .MuiOutlinedInput-root": {"& > fieldset": { borderColor: "white" }},
                                    "& .MuiOutlinedInput-root.Mui-focused": {"& > fieldset": {borderColor: "white"}},
                                    "& .MuiOutlinedInput-root:hover": { "& > fieldset": { borderColor: "white"}},
                                    marginRight:"15px", width:"125px", height:"35px"}}
                                  InputProps={{style: {fontSize:14.5, color:"white"}}} InputLabelProps={{style: {fontSize:14.5, color:"white"}}} />
                                  <Button variant="contained" size="small" 
                                  sx={{background:"#00963f", '&:hover':{backgroundColor:"#007330"}, fontSize:14.5, marginRight:"15px", height:"35px", textTransform:"none"}}
                                  onClick={(e) => {addProduct(e)}} onKeyDown={(e)=> addWithSubmit(e)}>
                                      Add
                                  </Button>
                              </Box>
                          </Box>
                          {selectedCompany.products.length !== 0 &&
                          <Table>
                              <TableHead>
                                  <TableRow sx={{background: "#1D1D1D"}}>
                                      <TableCell/>
                                      <TableCell sx={{fontSize:14.5, fontWeight:"bold", color:"white"}}>Product</TableCell>
                                      <TableCell sx={{fontSize:14.5, fontWeight:"bold", color:"white"}} align="right" >Total Licenses</TableCell>
                                      <TableCell sx={{fontSize:14.5, fontWeight:"bold", color:"white"}} align="right" >Active Licenses</TableCell>
                                      <TableCell sx={{fontSize:14.5, fontWeight:"bold", color:"white"}} align="right">Product Devices</TableCell>
                                      <TableCell sx={{fontSize:14.5, fontWeight:"bold", color:"white"}} align="right">Action</TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                  {selectedCompany.products.map((row) => (
                                    <Row hover key={row.name} row={row}/>
                                  ))}
                              </TableBody>
                          </Table>
                          }
                      </Paper>
                  </Box>
              </Box>
            }
          </Box>

          <Modal open={createAccountModalOpen} onClose={(e) => {setCreateAccountModalOpen(false); setNewAccount({SalesforceId: "",CompanyName: "",StreetAddress: "",City: "",ZipCode: "", State: "",PhoneNumber: "",PrimaryContact: "",Email: "", Type: "Admin"})}} 
          sx={{backdropFilter: "blur(0px)"}}>
            <Box sx={{position:"absolute", top:"40%", left:"50%", transform: "translate(-50%, -50%)", width:"460px", height:"450px",
              backgroundColor:"#202020", padding:"4", borderRadius:"5px"}}>
              <Typography flex={1} margin={"15px 15px 5px 15px"} minWidth={"120px"} fontSize={16} fontWeight={"bold"}>Create New Account</Typography>
              <Box sx={{width:"100%", display:"flex", justifyContent:"center"}}>
                <Box sx={{borderBottom:"1px solid white", width:"93%", marginBottom:"15px"}}></Box>
              </Box>
              <Box sx={{display:"flex", justifyContent:"center", width:"100%"}}>
                <Box sx={{width:"50%", display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <TextField name="sfID" size="small" variant="filled" label="Sales Force Id" value={newAccount.SalesforceId} onChange={(e) =>{handleNewAccountUpdate(e, newAccount)}} sx={{marginBottom:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14., color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                    <TextField name="email" size="small" variant="filled" label="Email" value={newAccount.Email} onChange={(e) =>{handleNewAccountUpdate(e, newAccount)}} sx={{marginBottom:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                    <TextField name="streetAddress" size="small" variant="filled" label="Street Address"  color={passwordTextFieldColor} value={newAccount.StreetAddress} onChange={(e) =>{handleNewAccountUpdate(e, newAccount)}} sx={{marginBottom:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                    <TextField name="state" size="small" variant="filled" label="State" color={passwordTextFieldColor} value={newAccount.State} onChange={(e) =>{handleNewAccountUpdate(e, newAccount)}} sx={{marginBottom:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                    <TextField name="phoneNumber" size="small" variant="filled" label="Phone Number" color={passwordTextFieldColor} value={newAccount.PhoneNumber} onChange={(e) =>{handleNewAccountUpdate(e, newAccount)}} sx={{marginBottom:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                </Box>
                <Box sx={{width:"50%", display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <TextField name="username" size="small" variant="filled" label="Username" color={passwordTextFieldColor} value={newAccount.Username} onChange={(e) =>{handleNewAccountUpdate(e, newAccount)}} sx={{marginBottom:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                    <TextField name="companyName" size="small" variant="filled" label="Company Name" color={passwordTextFieldColor} value={newAccount.CompanyName} onChange={(e) =>{handleNewAccountUpdate(e, newAccount)}} sx={{marginBottom:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                    <TextField name="city" size="small" variant="filled" label="City" color={passwordTextFieldColor} value={newAccount.City} onChange={(e) =>{handleNewAccountUpdate(e, newAccount)}} sx={{marginBottom:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                    <TextField name="areaCode" size="small" variant="filled" label="Area Code" color={passwordTextFieldColor} value={newAccount.ZipCode} onChange={(e) =>{handleNewAccountUpdate(e, newAccount)}} sx={{marginBottom:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                    <TextField name="contactName" size="small" variant="filled" label="Contact Name" color={passwordTextFieldColor} value={newAccount.PrimaryContact} onChange={(e) =>{handleNewAccountUpdate(e, newAccount)}} sx={{marginBottom:"15px", borderBottom: "1px solid white"}} InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                </Box>
              </Box>
              <Box display="flex" margin={"15px 15px 15px 15px"} alignItems={"center"} width={"100%"}>
                <Box sx={{float:"right"}}>
                  <Button variant="contained" color={"error"} sx={{fontSize:14, float:"right", textTransform:"none", marginLeft:"15px"}} onClick={(e) => {setCreateAccountModalOpen(false); setNewAccount({SalesforceId: "",CompanyName: "",StreetAddress: "",City: "",ZipCode: "", State: "",PhoneNumber: "",PrimaryContact: "",Email: "", Type: "Admin"})}}>
                      Close
                  </Button>
                  <Button variant="contained" sx={{fontSize:14, float:"right", textTransform:"none"}} onClick={(e) => {CreateAccount()}}>
                      Create Account
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>

          <Modal open={createModalOpen} onClose={(e) => {setCreateModalOpen(false); setNewUser({username: "", emailAddress: "", password: "", confirmPassword: ""})}} 
          sx={{backdropFilter: "blur(0px)"}}>
            <Box sx={{position:"absolute", top:"30%", left:"50%", transform: "translate(-50%, -50%)", width:"25%", height:"225px",
              backgroundColor:"#202020", padding:"4", borderRadius:"5px"}}>
              <Typography flex={1} margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={15}>Create new user</Typography>
              <Box display="flex" margin={"15px 15px 15px 15px"}>
                  <TextField name="username" size="small" variant="filled" label="Username" value={newUser.username} 
                  onChange={(e) =>{handleNewUserUpdate(e, newUser)}} 
                  sx={{width:"50%", marginRight:"15px", borderBottom: "1px solid white"}} 
                  InputProps={{style: {fontSize:14., color:"white"}, disableUnderline:true}} 
                  InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>

                  <TextField name="email" size="small" variant="filled" label="Email" value={newUser.emailAddress} 
                  onChange={(e) =>{handleNewUserUpdate(e, newUser)}} 
                  sx={{width:"50%", borderBottom: "1px solid white"}} 
                  InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                  InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                  </Box>
              <Box display="flex" margin={"15px 15px 15px 15px"} alignItems={"center"}>
                  <TextField name="password" size="small" variant="filled" label="Password" type="password" color={passwordTextFieldColor} value={newUser.password} 
                  onChange={(e) =>{handleNewUserUpdate(e, newUser)}} 
                  sx={{width:"50%", marginRight:"15px", borderBottom: passwordBorderBottom}} 
                  InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                  InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                  <TextField name="confirmPassword" size="small" variant="filled" label="Confirm Password" type="password" color={passwordTextFieldColor} value={newUser.confirmPassword} 
                  onChange={(e) =>{handleNewUserUpdate(e, newUser)}} 
                  sx={{width:"50%", borderBottom: passwordBorderBottom}} 
                  InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                  InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
              </Box>
              <Box display="flex" margin={"15px 15px 15px 15px"} alignItems={"center"}>
                <Typography fontSize={10} sx={{width:"50%", color:"red"}}>{passwordWarning}</Typography>
                <Box sx={{width:"50%"}}>
                    <Button variant="contained" sx={{fontSize:14, float:"right", textTransform:"none"}} onClick={(e) => {CreateUser()}}>
                        Create User
                    </Button>
                </Box>
              </Box>
            </Box>
          </Modal>

          <Modal open={deleteLicenseModal} onClose={(e) => {setDeleteLicenseModal(false)}} 
          sx={{backdropFilter: "blur(1px)"}}>
            <Box sx={{position:"absolute", top:"30%", left:"50%", transform: "translate(-50%, -50%)", width:"25%", height:"180px",
              backgroundColor:"#202020", padding:"4", borderRadius:"3px"}}>
              <Box>
                <Typography flex={1} margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={25} fontWeight={"500"} color={"white"}>Confirm Delete</Typography>
                <Typography flex={1} margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={15} fontWeight={"500"} color={"white"}>Are you sure you want to delete all the licenses associated with <strong>{licenseToDelete.name}</strong>?</Typography>
              </Box>
              <Box sx={{float:"right"}}>
                <Button variant="outlined" color="inherit" sx={{fontSize:14, textTransform:"none", boxShadow:0, background:"#eeeeee", color:"#62686e", '&:hover':{background:"#E3E3E3"}}} onClick={(e) => {setDeleteLicenseModal(false)}}>
                  Close
                </Button>
                <Button variant="contained" color="error" sx={{fontSize:14, textTransform:"none", marginRight:"10px", marginLeft:"15px", boxShadow:0}} onClick={(e) => {deleteProduct()}}>
                  Delete
                </Button>
              </Box>
            </Box>
          </Modal>

          <Modal open={deleteUserModal} onClose={(e) => {setDeleteUserModal(false)}} 
          sx={{backdropFilter: "blur(1px)"}}>
            <Box sx={{position:"absolute", top:"30%", left:"50%", transform: "translate(-50%, -50%)", width:"25%", height:"160px",
              backgroundColor:"#202020", padding:"4", borderRadius:"3px"}}>
              <Box>
                <Typography flex={1} margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={25} fontWeight={"500"} color={"white"}>Confirm Delete</Typography>
                <Typography flex={1} margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={15} fontWeight={"500"} color={"white"}>Are you sure you want to delete user <strong>{userToDelete.username}</strong>?</Typography>
              </Box>
              <Box sx={{float:"right"}}>
                <Button variant="outlined" color="inherit" sx={{fontSize:14, textTransform:"none", boxShadow:0, background:"#eeeeee", color:"#62686e", '&:hover':{background:"#E3E3E3"}}} onClick={(e) => {setDeleteUserModal(false)}}>
                  Close
                </Button>
                <Button variant="contained" color="error" sx={{fontSize:14, textTransform:"none", marginRight:"10px", marginLeft:"15px", boxShadow:0}} onClick={(e) => {DeleteUser()}}>
                  Delete
                </Button>
              </Box>
            </Box>
          </Modal>

          <Modal open={deleteUserDeviceLicenseModal} onClose={(e) => {setDeleteUserDeviceLicenseModal(false); setUserDeviceLicenseToDelete({});}} 
          sx={{backdropFilter: "blur(1px)"}}>
            <Box sx={{position:"absolute", top:"30%", left:"50%", transform: "translate(-50%, -50%)", width:"25%", height:"180px",
              backgroundColor:"#202020", padding:"4", borderRadius:"3px"}}>
              <Box>
                <Typography flex={1} margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={25} fontWeight={"500"} color={"white"}>Confirm Delete</Typography>
                <Typography flex={1} margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={15} fontWeight={"500"} color={"white"}>Are you sure you want to delete the <strong>{userDeviceLicenseToDelete.product}</strong> license for <strong>{userDeviceLicenseToDelete.hostName}</strong>?</Typography>
              </Box>
              <Box sx={{float:"right"}}>
                <Button variant="outlined" color="inherit" sx={{fontSize:14, textTransform:"none", boxShadow:0, background:"#eeeeee", color:"#62686e", '&:hover':{background:"#E3E3E3"}}} onClick={(e) => {setDeleteUserDeviceLicenseModal(false);}}>
                  Close
                </Button>
                <Button variant="contained" color="error" sx={{fontSize:14, textTransform:"none", marginRight:"10px", marginLeft:"15px", boxShadow:0}} onClick={(e) => {setDeleteUserDeviceLicenseModal(false); deleteUserDeviceLicense();}}>
                  Delete
                </Button>
              </Box>
            </Box>
          </Modal>
          <Footer />
      </Box>
  )
}
export default AdminAccountSearch;